<script>
  import { twMerge } from 'tailwind-merge'
  import { colors, baseSize, sizes, baseStyle } from './base-settings.js'

  export let size = baseSize
  export let role = 'img'
  export let color = 'currentColor'

  export let ariaLabel = 'shopping cart outline'

  $: fillColor = colors[color] || color
</script>

<svg
  xmlns="http://www.w3.org/2000/svg"
  fill={fillColor}
  {...$$restProps}
  class={twMerge(baseStyle, sizes[size ?? 'md'], $$props.class)}
  {role}
  aria-label={ariaLabel}
  tabindex="-1"
  viewBox="0 0 28 24"
  on:click
  on:keydown
  on:keyup
  on:focus
  on:blur
  on:mouseenter
  on:mouseleave
  on:mouseover
  on:mouseout
  xmlns:xlink="http://www.w3.org/1999/xlink"
>
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M3.35255 2.96812H0V0H4.17653C5.54174 0 6.64846 0.996653 6.64846 2.22609V3.75266H25.5262C27.2134 3.75266 28.4048 5.24124 27.8713 6.6827L23.4201 18.7082C23.0836 19.6172 22.139 20.2303 21.075 20.2303H5.82449C4.45928 20.2303 3.35255 19.2337 3.35255 18.0043V2.96812ZM6.64846 6.72078V17.2622H20.4811L24.383 6.72078H6.64846Z"
  />
  <path
    d="M8.33418 22.4989C8.33418 23.3279 7.58791 24 6.66735 24C5.74678 24 5.00051 23.3279 5.00051 22.4989C5.00051 21.6699 5.74678 20.9979 6.66735 20.9979C7.58791 20.9979 8.33418 21.6699 8.33418 22.4989Z"
  />
  <path
    d="M20.002 24C20.9226 24 21.6689 23.3279 21.6689 22.4989C21.6689 21.6699 20.9226 20.9979 20.002 20.9979C19.0815 20.9979 18.3352 21.6699 18.3352 22.4989C18.3352 23.3279 19.0815 24 20.002 24Z"
  />
</svg>
